import { MoonIcon, SunIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

function Header() {
  const [theme, setTheme] = useState(localStorage.theme || "system");

  const updateTheme = (value) => {
    setTheme(value);
    localStorage.setItem("theme", value);
    applyTheme(value);
  };

  const applyTheme = (value) => {
    if (value === "dark") {
      document.documentElement.classList.add("dark");
    } else if (value === "light") {
      document.documentElement.classList.remove("dark");
    } else {
      document.documentElement.classList.toggle(
        "dark",
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    }
  };

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);
  return (
    <header className="relative  dark:bg-zinc-800 mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
      <div className="flex lg:flex-1">
        <div className="-m-1.5 p-1.5">
          <span className="sr-only">CSV/TSV ⇄ JSON Converter</span>
          <h1 className="text-3xl font-semibold leading-6 text-blue-600">
            CSV/TSV ⇄ JSON Converter
          </h1>
          <h2 className="mt-6 text-base leading-6 text-gray-600 dark:text-gray-100">
            CSV、TSVとJSONを相互に変換するツールです。 <br />
            フロントエンドでのみ処理しており、バックエンドで入力データの保持は行っていません。
          </h2>
        </div>
      </div>
      <div>
        {theme !== "light" ? (
          <MoonIcon
            onClick={() => updateTheme("light")}
            className="cursor-pointer absolute right-6 top-6 h-8 w-8 text-gray-400 hover:text-blue-600"
          />
        ) : (
          <SunIcon
            onClick={() => updateTheme("dark")}
            className="cursor-pointer absolute right-6 top-6 h-8 w-8 text-gray-400 hover:text-blue-600"
          />
        )}
      </div>
    </header>
  );
}

export default Header;
