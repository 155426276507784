import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import Alert from "./Alert";

// フォーマット変換コンポーネント
function Converter({
  data,
  jsonData,
  format,
  handleTextChange,
  handleJsonDataChange,
  setFormat,
  formatOptions,
}) {
  // jsonDataをクリップボードにコピーする関数
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(jsonData);
    } catch (err) {
      <Alert message={"クリップボードへのコピーに失敗しました"} />;
    }
  };
  return (
    <div className="dark:bg-zinc-800">
      <div className="mx-auto max-w-7xl items-center justify-between px-6 py-4 lg:px-8">
        {/* <h1 className="text-xl font-semibold leading-6 text-gray-900">
          フォーマット
        </h1> */}
        <p className="mt-2 text-base text-gray-700 dark:text-gray-100">
          フォーマットの選択
        </p>
        <select
          onChange={(e) => {
            setFormat(e.target.value);
          }}
          value={format}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-zinc-800 dark:text-gray-100"
        >
          {formatOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="relative mx-auto flex max-w-7xl items-center justify-between pb-2 px-6 lg:px-8">
        <textarea
          rows={10}
          value={data}
          onChange={handleTextChange}
          placeholder="CSV/TSVデータをここに貼り付けてください"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-zinc-800 dark:text-gray-100"
        />
        <ArrowsRightLeftIcon className="h-16 w-16 text-gray-700 dark:text-gray-100" />
        <textarea
          rows={10}
          value={jsonData}
          onChange={handleJsonDataChange}
          placeholder="JSONデータがここに表示されます"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-zinc-800 dark:text-gray-100"
        />
        <DocumentDuplicateIcon
          onClick={copyToClipboard}
          className="invisible sm:visible cursor-pointer absolute right-14 top-2 h-8 w-8 text-gray-400 hover:text-blue-600"
        />
      </div>
    </div>
  );
}

export default Converter;
