// テーブル形式でのプレビューコンポーネント
function TablePreview({ data }) {
  if (!data || data.length === 0 || !data[0]) {
    return (
      <div className="mx-auto max-w-7xl items-center justify-between p-6 lg:px-8 dark:bg-zinc-800">
        <p className="mt-2 text-base text-gray-700 dark:text-gray-100">
          ここにプレビューが表示されます。
        </p>
      </div>
    );
  }

  const headers = Object.keys(data[0]);
  return (
    <div className="mx-auto max-w-7xl items-center justify-between p-6 lg:px-8 dark:bg-zinc-800">
      <h1 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-100">
        プレビュー
      </h1>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-blue-600">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3.5 text-left text-base font-bold text-gray-100"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-zinc-800 dark:text-gray-100">
                  {data.map((row, index) => (
                    <tr key={index}>
                      {headers.map((header, index) => (
                        <td
                          key={index}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-100"
                        >
                          {row[header]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablePreview;
