import { useState } from "react";
import { usePapaParse } from "react-papaparse"; // PapaParseライブラリを使用してCSV/TSVの解析とJSONへの変換を行う
import Alert from "./components/Alert";
import Converter from "./components/Converter";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ShareButton from "./components/ShareButton";
import TablePreview from "./components/TablePreview";

function App() {
  // 状態変数の定義
  const [data, setData] = useState(""); // ユーザーが入力するCSV/TSVデータを保持
  const [jsonData, setJsonData] = useState(""); // 変換されたJSONデータを保持
  const [error, setError] = useState(""); // エラーメッセージを保持
  const [format, setFormat] = useState("自動選択"); // 現在選択されているフォーマット（CSVまたはTSV）
  const formatOptions = ["自動選択", "CSV", "TSV"]; // フォーマットの選択肢
  const { readString, jsonToCSV } = usePapaParse(); // PapaParseの機能を利用

  // ShareButton用データ
  const url = "https://format-converter.netlify.app/";
  const quote = "[Tool] CSV/TSVとJSONの相互コンバーター";

  // CSV/TSVデータのフォーマットを検出する関数
  const detectFormat = (text) => {
    if (!text) {
      return "自動選択";
    }
    const commaCount = text.split(",").length - 1;
    const tabCount = text.split("\t").length - 1;

    return commaCount > tabCount ? "CSV" : "TSV";
  };

  // テキスト入力欄での変更を処理する関数
  const handleTextChange = (event) => {
    const newData = event.target.value;
    setData(newData);

    if (newData.trim() === "") {
      setJsonData(""); // CSV/TSVデータが空の場合、JSONデータも空にする
      setFormat("自動選択"); // テキストエリアが空の場合、フォーマットを「自動選択」に設定
      setError(""); // エラーを空にする
    } else {
      const detectedFormat = detectFormat(newData);
      setFormat(detectedFormat);
      handleCsvOrTsvToJson(newData, format);
    }
  };

  // CSV/TSVからJSONへの変換を行う関数
  const handleCsvOrTsvToJson = (data, format) => {
    readString(data, {
      delimiter: format === "CSV" ? "," : "\t",
      header: true,
      worker: true,
      skipEmptyLines: true,
      complete: (results) => {
        setJsonData(JSON.stringify(results.data, null, 2));
      },
    });
  };

  // JSONデータ入力欄での変更を処理する関数
  const handleJsonDataChange = (event) => {
    const newJsonData = event.target.value;
    setJsonData(newJsonData);

    if (newJsonData.trim() === "") {
      setData(""); // JSONデータが空の場合、CSV/TSVデータも空にする
      setFormat("自動選択"); // JSONデータが空の場合、フォーマットを「自動選択」に設定
      setError(""); // エラーを空にする
    } else {
      handleJsonToCsvOrTsv(newJsonData, format);
    }
  };

  // JSONからCSV/TSVへの変換を行う関数
  const handleJsonToCsvOrTsv = (jsonData, format) => {
    try {
      const converted = jsonToCSV(jsonData, {
        delimiter: format === "CSV" ? "," : "\t",
        header: true,
        skipEmptyLines: true,
      });
      setData(converted);
    } catch (err) {
      setError("無効なJSONデータです。");
    }
  };

  // プレビュー用のデータを取得する関数
  const getParsedDataForPreview = () => {
    try {
      return JSON.parse(jsonData);
    } catch (err) {
      return [];
    }
  };

  // コンポーネントの描画
  return (
    <div className="dark:bg-zinc-800 min-h-screen">
      <Header />
      <main>
        {error && <Alert message={error} />}
        <Converter
          data={data}
          jsonData={jsonData}
          format={format}
          handleTextChange={handleTextChange}
          handleJsonDataChange={handleJsonDataChange}
          setFormat={setFormat}
          formatOptions={formatOptions}
        />
        <ShareButton url={url} quote={quote} />
        <TablePreview data={getParsedDataForPreview()} />
      </main>
      <Footer />
    </div>
  );
}

export default App;
