import {
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  LineIcon,
  LineShareButton,
  PocketIcon,
  PocketShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";

function ShareButton({ url, quote }) {
  const iconSize = 30;
  const iconStyle = { fill: "#2563eb" };
  return (
    <div className="mx-auto max-w-7xl items-center flex justify-end px-6 lg:px-8 space-x-2 dark:bg-zinc-800">
      <TwitterShareButton url={url} title={quote}>
        <XIcon size={iconSize} round bgStyle={iconStyle} />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={quote}>
        <FacebookIcon size={iconSize} round bgStyle={iconStyle} />
      </FacebookShareButton>
      <PocketShareButton url={url} title={quote}>
        <PocketIcon size={iconSize} round bgStyle={iconStyle} />
      </PocketShareButton>
      <LineShareButton url={url} title={quote}>
        <LineIcon size={iconSize} round bgStyle={iconStyle} />
      </LineShareButton>
      <HatenaShareButton url={url} title={quote}>
        <HatenaIcon size={iconSize} round bgStyle={iconStyle} />
      </HatenaShareButton>
    </div>
  );
}

export default ShareButton;
